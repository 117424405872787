<template>
  <div class="app-main">
    <div class="app-container">
      <div class="position">
        <h2>品牌项目</h2>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>品牌项目</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="brand">
        <el-row :gutter="24" >
          <el-col :xs="24" :sm="6" :md="6" :lg="6"> 
            <a><img src="@/assets/img/1-1ZR315344A09.jpg" alt="急性扭挫伤健康管理"><span>急性扭挫伤健康管理</span></a>
          </el-col>
          <el-col :xs="24" :sm="6" :md="6" :lg="6"> 
            <a><img src="@/assets/img/1-1ZR315342V40.jpg" alt="疾病后期复健"><span>疾病后期复健</span></a>
          </el-col>
          <el-col :xs="24" :sm="6" :md="6" :lg="6">
            <a><img src="@/assets/img/1-1ZR3153404364.jpg" alt="肩周炎健康管理"><span>肩周炎健康管理</span></a>
            
          </el-col>
          <el-col :xs="24" :sm="6" :md="6" :lg="6">
            <a><img src="@/assets/img/1-1ZR3153255K4.jpg" alt="乳腺健康管理"><span>乳腺健康管理</span></a>
            
          </el-col>
          <el-col :xs="24" :sm="6" :md="6" :lg="6">
            <a><img src="@/assets/img/1-1ZR315322I09.jpg" alt="全年亚健康调理"><span>全年亚健康调理</span></a>
            
          </el-col>
          <el-col :xs="24" :sm="6" :md="6" :lg="6">
            <a><img src="@/assets/img/1-1ZR3153211443.jpg" alt="青少年近视健康管理"><span>青少年近视健康管理</span></a>
            
          </el-col>
          <el-col :xs="24" :sm="6" :md="6" :lg="6">
            <a><img src="@/assets/img/1-1ZR315315B26.jpg" alt="脾胃健康管理"><span>脾胃健康管理</span></a>
            
          </el-col>
          <el-col :xs="24" :sm="6" :md="6" :lg="6">
            <a><img src="@/assets/img/1-1ZR315313OJ.jpg" alt="慢性疲劳综合症调理"><span>慢性疲劳综合症调理</span></a>
            
          </el-col>
          <el-col :xs="24" :sm="6" :md="6" :lg="6">
            <a><img src="@/assets/img/1-1ZR0161125a7.png" alt="补肾壮腰健康管理"><span>补肾壮腰健康管理</span></a>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  components: {
    // CategoryNav,
  },
  methods: {
  },
  watch: {
    
  },
};
</script>

<style lang="less" scoped>
.app-content {
	padding: 20px 10px;
	overflow: hidden;
  .ul{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    li{
      width: 25%;
      a{
        display: block;
        padding: 5px;
        img{
          width: 100%;
          display: block;
        }
        span{
          display: block;
          background: #000;
          color: #fff;
          padding: 5px;
          text-align: center;
        }
      }
      
    }
  }
}
</style>